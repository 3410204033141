import { ValidationError } from 'yup';

interface ErrorsReturn {
  [key: string]: string;
}

export default function getValidationErrors(
  err: ValidationError,
): ErrorsReturn {
  const validationErrors: ErrorsReturn = {};

  err.inner.forEach(error => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
}
